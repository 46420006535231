body {
    background-color: #000000;
    color: #40e07d;
    font-family: 'Courier New', monospace;
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
}
.container {
    max-width: 50rem;
    margin: 0 auto;
    border-left: 1px dashed rgba(64, 224, 125, 0.3);
    border-right: 1px dashed rgba(64, 224, 125, 0.3);
    min-height: 100vh;
    padding: 0rem;
}
.header {
    display: inline-block;
    gap: 0rem;
    max-width: 100%;
    overflow: hidden;
    padding-left: 138px;
    flex-direction: column;
}
@media (min-width: 640px) {
    .header {
        flex-direction: row;
    }
}
.profile-image {
    width: 15rem;
    height: 4rem;
    font-size: 10px;
    line-height: 12px;
    white-space: pre;
    overflow: visible;
    opacity: 1;
    padding-left: 330px;
}
.profile-info h1 {
    margin-top: -9px; 
    margin-bottom: 5px; 
  }
.profile-info .quote {
  margin-top: 58px; 
}


.profile-info p {
    margin: 0px;
    padding-bottom: 4px;

}

.quote {
    font-style: italic;
    opacity: 0.7;
    font-size: 0.75rem;
}
.binary-line {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgba(64, 224, 125, 0.75);
    color: black;
    font-size: 0.875rem;
    padding: 0.1rem 0;
  }
  
  .binary-content {
    display: inline-block;
    padding-left: 0%;
    animation: scroll 50s linear infinite;
  }
  
  .binary-content::after {
    content: "01111001 01101111 01110101 00100000 01110010 01100101 01100001 01101100 01101100 01111001 00100000 01100011 01101000 01100101 01100011 01101011 01100101 01100100 00100000 01110111 01101000 01100001 01110100 00100000 01110100 01101000 01100101 00100000 01101101 01100101 01110011 01110011 01100001 01100111 01100101 00100000 01101001 01110011 00101100 00100000 01101000 01110101 01101000 00111111";
  }
  
  @keyframes scroll {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }
.section {
    margin-top: 1.5rem;
    padding: 0 1rem;
}
.section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    
}
.projects-list {
    list-style-type: none;
    padding: 0;
    opacity: 0.7;
}
.projects-list li {
    margin-bottom: 0.5rem;
}
.projects-list a {
    color: #40e07d;
    text-decoration: none;
}
.projects-list a:hover {
    text-decoration: underline;
}

.terminal {
    height: 466px;
    background-color: rgba(64, 224, 125, 0.1);
    border: 1px solid #40e07d;
    font-family: 'Courier New', monospace;
    padding: 10px;
    overflow-y: auto;
  }
  
  .terminal-output {
    white-space: pre-wrap;
    word-break: break-word;
  }
  
  .input-line {
    display: flex;
    align-items: center;
  }
  
  .input-line span {
    color: #40e07d;
    margin-right: 5px;
  }
  
  .input-line input {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    color: #40e07d;
    font-family: 'Courier New', monospace;
    font-size: 1rem;
  }
  
  .input-line input:focus {
    outline: none;
  }